<template>
  <div class="page-content">
    <div v-if="visibilityOngletSetting">
      <div class="header">
        <div class="flex-center">
          <div class="style-title-setting-global">
            {{ $t('users') }}
          </div>
        </div>
        <v-progress-circular
          v-if="getUserLoading"
          class="float-right mt-1"
          indeterminate
          color="#5C2DD3"
        ></v-progress-circular>
        <v-btn
          v-if="
            currentUser &&
              currentUser.type != 'user.final' &&
              selectedMenu == 'users'
          "
          dark
          color="#5C2DD3"
          @click.stop="handleClickBtnAddUsers()"
        >
          <v-icon class="sz-icon-plus mr-1">mdi-plus</v-icon>
          {{ $i18n.locale === 'fr' ? 'Ajouter un utilisateur' : 'Add User' }}
        </v-btn>

        <v-btn
          v-if="
            currentUser && currentUser.isSuper && selectedMenu == 'permission'
          "
          @click.stop="handleClickBtnAddPermission()"
          dark
          color="#5C2DD3"
        >
          <v-icon class="sz-icon-plus mr-1 ">mdi-plus</v-icon>
          {{ $t('add', { msg: $t('configPermission.name') }) }}
        </v-btn>
      </div>
      <div class="block-menu-users mt-2">
        <div class="list-tabs">
          <v-tabs
            bg-color="#5C2DD3"
            :hide-slider="false"
            slider-color="#5C2DD3"
            color="#5C2DD3"
            item-color="#5C2DD3"
            v-model="model"
          >
            <v-tab
              href="#tab-0"
              color="#5C2DD3"
              item-color="#5C2DD3"
              @click="clickMenu('users', '/setting/users')"
            >
              <div class="menu-item">
                {{ $t('utilisateurs') }}
              </div>
            </v-tab>
            <v-tab
              href="#tab-1"
              color="#5C2DD3"
              item-color="#5C2DD3"
              @click="clickMenu('permission', '/setting/users/permissions')"
              v-if="currentUser && currentUser.isSuper"
            >
              <div class="menu-item">
                {{ $t('autorisations') }}
              </div>
            </v-tab>
            <v-tab
              href="#tab-2"
              color="#5C2DD3"
              item-color="#5C2DD3"
              @click="clickMenu('corbeille', '/setting/users/corbeilles')"
              v-if="(currentUser && currentUser.isSuper) || computedRegieCom"
            >
              <div>
                {{ $t('corbeille') }}
              </div>
            </v-tab>
          </v-tabs>
        </div>
      </div>
    </div>
    <div class="content">
      <router-view ref="sortUsersView" />
    </div>
    <!-- Modal users -->
    <v-dialog v-model="addUserModal" max-width="600" persistent scrollable>
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">{{
            currentUser && currentUser.type == 'user.commercial'
              ? `${$t('add', { msg: $t('configUser.name') })} final`
              : $i18n.locale === 'fr'
              ? 'Ajouter un utilisateur'
              : 'Add user'
          }}</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('addUserModal')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-form ref="newUserForm" class="mt-2">
            <v-row>
              <v-col>
                <v-text-field
                  :label="$t('first_name')"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  v-model="userToAdd.first_name"
                  item-color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[v => !!v || $t('first_name') + ' ' + $t('msgOblg')]"
                >
                </v-text-field
              ></v-col>
              <v-col>
                <v-text-field
                  :label="$t('name')"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  v-model="userToAdd.last_name"
                  item-color="#5C2DD3"
                >
                </v-text-field
              ></v-col>
            </v-row>
            <v-row v-if="currentUser && currentUser.isSuper">
              <v-col>
                <v-text-field
                  label="Pipedrive api token"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  v-model="userToAdd.api_pipe_token"
                  item-color="#5C2DD3"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="currentUser && currentUser.isSuper">
              <v-col>
                <v-text-field
                  :label="$t('phone')"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  v-model="userToAdd.phone"
                  type="tel"
                  item-color="#5C2DD3"
                >
                </v-text-field
              ></v-col>
              <v-col>
                <v-text-field
                  :label="$t('zip_code')"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  v-model="userToAdd.zipcode"
                  item-color="#5C2DD3"
                >
                </v-text-field
              ></v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="E-mail"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  v-model="userToAdd.email"
                  required
                  validate-on-blur
                  :rules="[v => !!v || 'E-mail  ' + $t('msgOblg')]"
                  item-color="#5C2DD3"
                >
                </v-text-field
              ></v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  :label="$t('password')"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  type="password"
                  v-model="userToAdd.password"
                  required
                  validate-on-blur
                  :rules="[v => !!v || $t('password') + ' ' + $t('msgOblg')]"
                  item-color="#5C2DD3"
                >
                </v-text-field
              ></v-col>
              <v-col>
                <v-text-field
                  :label="$t('confirm_password')"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  type="password"
                  v-model="userToAdd.password_confirmation"
                  required
                  validate-on-blur
                  :rules="[
                    v => !!v || $t('confirm_password') + ' ' + $t('msgOblg')
                  ]"
                  item-color="#5C2DD3"
                >
                </v-text-field
              ></v-col>
            </v-row>

            <!-- CRM PIXEL -->
            <v-row v-if="computedVoirChamps">
              <v-col>
                <v-text-field
                  label="Code client"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  v-model="userToAdd.code_client"
                  required
                  item-color="#5C2DD3"
                  validate-on-blur
                  :rules="[v => !!v || 'Code client ' + $t('msgOblg')]"
                >
                </v-text-field
              ></v-col>
              <v-col>
                <v-text-field
                  :label="$t('company')"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  v-model="userToAdd.societe"
                  required
                  validate-on-blur
                  :rules="[v => !!v || $t('company') + ' ' + $t('msgOblg')]"
                  item-color="#5C2DD3"
                >
                </v-text-field
              ></v-col>
            </v-row>
            <v-row v-if="computedVoirChamps">
              <v-col>
                <v-select
                  :label="
                    $i18n.locale === 'fr'
                      ? 'Nombre des appareils maximales'
                      : 'Maximum number of devices'
                  "
                  dense
                  v-model="userToAdd.nombre_appareil"
                  item-text="name"
                  item-value="id"
                  :items="optionsAppareilMax"
                  outlined
                  :persistent-placeholder="true"
                  required
                  validate-on-blur
                  :rules="[
                    v =>
                      !!v ||
                      ($i18n.locale === 'fr'
                        ? 'Nombre des appareils maximales'
                        : 'Maximum number of devices') +
                        ' ' +
                        $t('msgOblg')
                  ]"
                  class="msg-error mt-2"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                ></v-select
              ></v-col>
              <v-col>
                <v-text-field
                  :label="
                    $i18n.locale === 'fr' ? 'Période d\'essai' : 'Trial period'
                  "
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  type="number"
                  v-model="userToAdd.periode_test"
                  min="1"
                  max="30"
                  item-color="#5C2DD3"
                >
                </v-text-field>
                <!-- <span class="label-text"> Choisir une valeur entre 1 et 30</span> --></v-col
              >
            </v-row>
            <v-row v-if="currentUser && currentUser.isSuper">
              <v-col>
                <v-select
                  label="Types"
                  dense
                  v-model="userToAdd.type"
                  required
                  @input="typeUsers"
                  item-text="name"
                  item-value="id"
                  :items="computedFormatTypesUsers"
                  outlined
                  :persistent-placeholder="true"
                  validate-on-blur
                  :rules="[v => !!v || 'Type ' + $t('msgOblg')]"
                  class="msg-error mt-2"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                ></v-select>
              </v-col>
            </v-row>

            <v-row v-if="userToAdd.type && currentUser && currentUser.isSuper">
              <v-col class="margin-switch mr-3">
                <v-switch
                  class="input-checkbox switch-bottom"
                  label="Export Excel"
                  color="#5C2DD3"
                  v-if="
                    userToAdd.type == 'user.admin' ||
                      userToAdd.type == 'user.sub-super-admin'
                  "
                  v-model="checkedExport"
                  :disabled="
                    userToAdd.type == 'user.admin' ||
                      userToAdd.type == 'user.sub-super-admin'
                  "
                >
                </v-switch>
                <v-switch
                  class="input-checkbox switch-bottom"
                  v-else
                  label="Export Excel"
                  color="#5C2DD3"
                  v-model="userToAdd.has_export_excel"
                >
                </v-switch
              ></v-col>
              <v-col class="ml-3"
                ><v-switch
                  class="input-checkbox switch-bottom"
                  label="Import Excel"
                  color="#5C2DD3"
                  v-model="userToAdd.has_import_excel"
                >
                </v-switch
              ></v-col>
            </v-row>

            <v-row
              v-if="
                computedRegie &&
                  currentUser.type != 'user.commercial' &&
                  currentUser.type != 'user.final'
              "
            >
              <v-col>
                <v-select
                  label="Type"
                  dense
                  v-model="userToAdd.typeRegie"
                  required
                  @input="typeUsers"
                  item-text="text"
                  item-value="id"
                  :items="usertypes"
                  outlined
                  :persistent-placeholder="true"
                  validate-on-blur
                  :rules="[v => !!v || 'Type ' + $t('msgOblg')]"
                  class="msg-error mt-2"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                ></v-select
              ></v-col>
            </v-row>
            <v-row
              v-if="
                userToAdd.type == 'user.final' ||
                  userToAdd.typeRegie == 'Utilisateur Final'
              "
            >
              <v-col
                v-if="
                  listCommercailsUser &&
                    listCommercailsUser.data &&
                    listCommercailsUser.data.length
                "
              >
                <v-select
                  :label="$t('Superior')"
                  dense
                  v-model="userToAdd.superior_id"
                  required
                  item-text="name"
                  item-value="id"
                  :items="listCommercailsUser.data || []"
                  outlined
                  :no-data-text="$t('noDataOption')"
                  :persistent-placeholder="true"
                  validate-on-blur
                  :rules="[v => !!v || $t('Superior') + ' ' + $t('msgOblg')]"
                  class="msg-error mt-2"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                ></v-select
              ></v-col>
              <v-col v-else>
                <v-select
                  :label="$t('Superior')"
                  dense
                  v-model="userToAdd.superior_id"
                  required
                  item-text="id"
                  item-value="id"
                  :items="listCommercailsUser.data || []"
                  outlined
                  :no-data-text="$t('noDataOption')"
                  :persistent-placeholder="true"
                  validate-on-blur
                  :rules="[v => !!v || $t('Superior') + ' ' + $t('msgOblg')]"
                  class="msg-error mt-2"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                ></v-select
              ></v-col>
            </v-row>
            <v-row v-if="userToAdd.type == 'user.technicien'">
              <v-col>
                <v-select
                  label="Co-Poseur"
                  dense
                  v-model="userToAdd.co_technician_id"
                  @input="typeUsers"
                  item-text="full_name"
                  item-value="id"
                  :items="[...getProjectsUsersTechniciens]"
                  outlined
                  :no-data-text="$t('noDataOption')"
                  :persistent-placeholder="true"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                ></v-select
              ></v-col>
              <v-col>
                <v-select
                  :label="
                    $i18n.locale === 'fr'
                      ? 'Techniciens dans pipedrive'
                      : 'Pipedrive technicians'
                  "
                  dense
                  v-model="userToAdd.technician_id_pipe"
                  item-text="label"
                  item-value="id_tech_pipe"
                  :items="technicianspipedrive"
                  outlined
                  :no-data-text="$t('noDataOption')"
                  :persistent-placeholder="true"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                ></v-select
              ></v-col>
            </v-row>

            <v-row v-if="userToAdd.type == 'user.commercial.ite'">
              <v-col>
                <v-select
                  :label="
                    $i18n.locale === 'fr'
                      ? 'Commerciaux dans Pipedrive'
                      : 'Pipedrive Sales'
                  "
                  dense
                  v-model="userToAdd.commercial_ite_id_pipe"
                  item-text="label"
                  item-value="id_comm_ite_pipe"
                  :items="commerciauxpipedrive"
                  outlined
                  :no-data-text="$t('noDataOption')"
                  :persistent-placeholder="true"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                ></v-select
              ></v-col>
            </v-row>

            <v-row
              v-if="
                userToAdd.type == 'user.commercial' ||
                  userToAdd.typeRegie == 'Commercial'
              "
            >
              <v-col>
                <v-text-field
                  label="Commission 101"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  type="number"
                  v-model="userToAdd.coeff_101"
                  item-color="#5C2DD3"
                >
                </v-text-field
              ></v-col>
              <v-col>
                <v-text-field
                  label="Commission 102"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  type="number"
                  v-model="userToAdd.coeff_102"
                  item-color="#5C2DD3"
                >
                </v-text-field
              ></v-col>
              <v-col>
                <v-text-field
                  label="Commission 103"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  type="number"
                  v-model="userToAdd.coeff_103"
                  item-color="#5C2DD3"
                >
                </v-text-field
              ></v-col>
            </v-row>
            <v-row
              v-if="
                currentUser &&
                  currentUser.isSuper &&
                  (userToAdd.type == 'user.commercial' ||
                    userToAdd.type == 'user.final')
              "
            >
              <v-col>
                <v-select
                  :label="$i18n.locale === 'fr' ? 'Proprietaire' : 'Owner'"
                  dense
                  v-model="userToAdd.owner_id"
                  item-text="full_name"
                  item-value="id"
                  :items="property"
                  outlined
                  :persistent-placeholder="true"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                ></v-select
              ></v-col>
            </v-row>
            <v-row
              v-if="
                userToAdd.type == 'user.admin' ||
                  userToAdd.type == 'user.agent' ||
                  userToAdd.type == 'user.resp.plan' ||
                  userToAdd.type == 'user.administration'
              "
            >
              <v-col>
                <v-select
                  :label="
                    $i18n.locale === 'fr'
                      ? 'Utilisateur pipedrive'
                      : 'Pipedrive user'
                  "
                  dense
                  v-model="userToAdd.pipe_user_id"
                  item-text="label"
                  item-value="id"
                  :no-data-text="$t('noDataOption')"
                  :items="[...usersPipedriveNoSelectionne]"
                  outlined
                  :persistent-placeholder="true"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="loading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="error" class="error-msg">
              <ul v-if="Array.isArray(error)">
                <li v-for="(e, index) in error" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ error }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="handleSubmitUsers"
            :loading="loading"
            small
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('addUserModal')" small>
            {{ $t('btnCancel') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- permission -->

    <v-dialog
      v-model="addPermissionModal"
      max-width="600"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">{{
            $i18n.locale === 'fr'
              ? "Ajouter un ensemble d'autorisations"
              : 'Add set of permissions'
          }}</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('addPermissionModal')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-form class="mt-2" ref="addPermissionModal">
            <v-row>
              <v-col>
                <v-text-field
                  :label="$t('name')"
                  dense
                  v-model="permissionToAdd.name"
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[v => !!v || $t('name') + ' ' + $t('msgOblg')]"
                >
                </v-text-field
              ></v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-autocomplete
                  :clearable="true"
                  color="#5C2DD3"
                  v-model="permissionToAdd.users"
                  multiple
                  :placeholder="$t('search')"
                  item-color="#5C2DD3"
                  :items="getListeUsers || []"
                  :persistent-placeholder="true"
                  chips
                  return-object
                  :deletable-chips="true"
                  :small-chips="true"
                  :label="$t('utilisateurs')"
                  class="mb-0"
                  item-text="full_name"
                  dense
                  item-value="id"
                  :auto-focus="false"
                  :no-data-text="$t('noDataOption')"
                  outlined
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                </v-autocomplete
              ></v-col>
            </v-row>
            <v-row class="autorisation">
              <v-col>
                <div class="mb-3">
                  {{
                    $i18n.locale === 'fr' ? 'Autorisations :' : 'Permissions :'
                  }}
                </div>
                <div v-if="getListePermission && getListePermission.length">
                  <v-switch
                    class="input-checkbox"
                    color="#5C2DD3"
                    v-for="item in getListePermission"
                    :key="'permission ' + item.id"
                    :value="item.id"
                    v-model="permissionToAdd.permission"
                    :label="item.name"
                  ></v-switch>
                </div>
                <div v-else class="font-text font-sz-12 color-crm text-center">
                  {{
                    $i18n.locale === 'fr'
                      ? 'Aucune Autorisation'
                      : 'No permissions'
                  }}
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getPermissionLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getPermissionError" class="error-msg">
              <ul v-if="Array.isArray(getPermissionError)">
                <li v-for="(e, index) in getPermissionError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getPermissionError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="handleSubmitPermission"
            :loading="getPermissionLoading"
            small
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('addPermissionModal')" small>
            {{ $t('btnCancel') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'users',
  data() {
    return {
      model: 'tab-0',
      selectedMenu: 'users',
      permissionToAdd: {
        name: null,
        users: [],
        permission: []
      },
      userToAdd: {
        first_name: null,
        last_name: null,
        last_name_update: null,
        first_name_update: null,
        email: null,
        api_pipe_token: null,
        password: null,
        password_confirmation: null,
        type: '',
        typeRegie: '',
        superior_id: null,
        coeff_101: null,
        coeff_102: null,
        coeff_103: null,
        co_technician_id: null,
        technician_id_pipe: null,
        commercial_ite_id_pipe: null,
        pipe_user_id: null,
        owner_id: null,
        phone: null,
        zipcode: null,
        has_export_excel: 0,
        has_import_excel: 0,
        nombre_appareil: 11,
        periode_test: null
      },
      optionsAppareilMax: [
        { id: 5, name: '5' },
        { id: 10, name: '10' },
        { id: 11, name: 'Plus de 10' }
      ],
      checkedExport: true,
      loading: false,
      initLoading: true,
      error: null,
      addUserModal: false,
      addPermissionModal: false
    }
  },
  methods: {
    ...mapActions([
      'addUser',
      'getlistCommercailsUser',
      'getTechnicianspipedrive',
      'getUsersPipedrive',
      'getCommerciauxPipedrive',
      'addPermisssionSet',
      'resetErrorPermission',
      'fetchUsersTechniciens',
      'fetchProprieteRegie'
    ]),
    closeDialog(ref) {
      this[ref] = false
      if (this.$refs.newUserForm) {
        this.$refs.newUserForm.resetValidation()
      }
      if (ref == 'addPermissionModal') {
        this.$refs.addPermissionModal.resetValidation()
      }
      this.resetModal()
    },
    resetModal() {
      this.permissionToAdd = {
        name: null,
        users: [],
        permission: []
      }
      this.resetErrorPermission()
      this.userToAdd = {
        first_name: null,
        last_name: null,
        last_name_update: null,
        first_name_update: null,
        email: null,
        api_pipe_token: null,
        password: null,
        password_confirmation: null,
        type: '',
        typeRegie: '',
        superior_id: null,
        coeff_101: null,
        coeff_102: null,
        coeff_103: null,
        co_technician_id: null,
        technician_id_pipe: null,
        commercial_ite_id_pipe: null,
        pipe_user_id: null,
        owner_id: null,
        phone: null,
        zipcode: null,
        has_export_excel: 0,
        has_import_excel: 0,
        code_client: null,
        societe: null,
        nombre_appareil: 11,
        periode_test: null
      }
      this.loading = false
      this.error = null
    },
    typeUsers() {
      this.userToAdd.has_export_excel = 0
      this.userToAdd.has_import_excel = 0
    },
    async handleSubmitUsers() {
      if (this.$refs.newUserForm.validate()) {
        this.loading = true
        this.error = null
        if (this.userToAdd.password != this.userToAdd.password_confirmation) {
          this.loading = false
          this.error = [
            {
              password:
                this.$i18n.locale === 'fr'
                  ? 'le mot de passe et la confirmation du mot de passe doivent être égaux'
                  : 'password and password confirmation must be equal'
            }
          ]
          return
        }
        if (this.userToAdd.password.length < 6) {
          this.loading = false
          this.error = [
            {
              password:
                this.$i18n.locale === 'fr'
                  ? 'le mot de passe doit contenir au moins 6 caractères'
                  : 'password must contain at least 6 characters'
            }
          ]
          return
        }
        if (this.computedRegie) {
          if (this.userToAdd.typeRegie == 'Commercial') {
            this.userToAdd.type = 'user.commercial'
          } else {
            this.userToAdd.type = 'user.final'
          }
        }
        const response = await this.addUser(this.userToAdd)
        if (response.success) {
          this.loading = false
          this.closeDialog('addUserModal')
          if (!this.computedVoirChamps) {
            this.getlistCommercailsUser()
          }
          this.getTechnicianspipedrive()
          this.getUsersPipedrive()
          this.getCommerciauxPipedrive()
          this.fetchProprieteRegie({
            exclude: ['visibilityFilter', 'team', 'visibleTeams', 'userPipe']
          })
          this.fetchUsersTechniciens()
          if (
            this.$refs['sortUsersView'] &&
            typeof this.$refs['sortUsersView'].SortUsers == 'function'
          ) {
            this.$refs['sortUsersView'].SortUsers()
          }
        } else {
          this.loading = false
          this.error = response.error
        }
      }
    },
    async handleClickBtnAddPermission() {
      this.addPermissionModal = true
    },
    async handleClickBtnAddUsers() {
      this.addUserModal = true
      this.fetchProprieteRegie({
        exclude: ['visibilityFilter', 'team', 'visibleTeams', 'userPipe']
      })
    },
    clickMenu(menu, url) {
      this.selectedMenu = menu
      this.$router.push(url)
    },
    async handleSubmitPermission() {
      if (this.$refs.addPermissionModal.validate()) {
        const payload = {
          name: this.permissionToAdd.name,
          users: this.permissionToAdd.users.map(user => {
            return user.id
          }),
          permission: [...this.permissionToAdd.permission]
        }
        const response = await this.addPermisssionSet(payload)
        if (response) {
          this.closeDialog('addPermissionModal')
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'usertypes',
      'listCommercailsUser',
      'getUserLoading',
      'technicianspipedrive',
      'commerciauxpipedrive',
      'usersPipedriveNoSelectionne',
      'getListePermission',
      'getListeUsers',
      'getPermissionLoading',
      'getPermissionError',
      'getProjectsUsersTechniciens',
      'property',
      'currentUser'
    ]),
    visibilityOngletSetting: function() {
      if (
        this.$route &&
        (this.$route.name == 'users' ||
          this.$route.name == 'permission' ||
          this.$route.name == 'corbeille')
      ) {
        return true
      }
      return false
    },
    computedFormatTypesUsers() {
      if (this.usertypes && this.usertypes.length) {
        const options = []
        for (const group of this.usertypes) {
          if (Array.isArray(group.types)) {
            let objetGroupe = { header: group.groupe }
            options.push(
              objetGroupe,
              ...group.types.map(type => ({ ...type, groupe: group.groupe }))
            )
          }
        }
        return options
      }
      return []
    },
    computedRegieCom: function() {
      if (
        this.currentUser &&
        this.currentUser.details &&
        this.currentUser.details.team &&
        this.currentUser.details.team.data &&
        this.currentUser.details.team.data.type === 'regie' &&
        this.currentUser.type == 'user.commercial'
      ) {
        return true
      }
      return false
    },
    computedVoirChamps: function() {
      if (
        this.currentUser &&
        this.currentUser.code_client != null &&
        this.currentUser.details &&
        this.currentUser.details.team &&
        this.currentUser.details.team.data &&
        this.currentUser.details.team.data.type === 'regie' &&
        this.currentUser.type == 'user.commercial'
      ) {
        return true
      }
      return false
    },
    computedRegie: function() {
      return (
        this.currentUser &&
        this.currentUser.type == 'user.admin' &&
        this.currentUser.details &&
        this.currentUser.details.team &&
        this.currentUser.details.team.data &&
        this.currentUser.details.team.data.type === 'regie'
      )
    }
  },
  watch: {
    $route(route) {
      if (route.path === '/setting/users/permissions') {
        this.selectedMenu = 'permission'
        this.model = 'tab-1'
      } else if (route.path === '/setting/users/corbeilles') {
        this.selectedMenu = 'corbeille'
        this.model = 'tab-2'
      } else {
        this.selectedMenu = 'users'
        this.model = 'tab-0'
      }
    }
  },
  mounted() {
    if (this.$route.path === '/setting/users/permissions') {
      this.selectedMenu = 'permission'
      this.model = 'tab-1'
    } else if (this.$route.path === '/setting/users/corbeilles') {
      this.selectedMenu = 'corbeille'
      this.model = 'tab-2'
    } else {
      this.selectedMenu = 'users'
      this.model = 'tab-0'
    }
  }
}
</script>

<style lang="scss" scoped>
.page-content {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
  .btn-filter {
    font-family: 'Montserrat', sans-serif;
    border-radius: 4px;
    padding: 1px 0px 0px 0px;
    font-size: 12px;

    .sz-icon-plus {
      font-size: 20px !important;
    }
  }
  .block-menu-users {
    .list-tabs {
      background-color: #f6f6f6;
      display: flex;
      align-items: center;
    }
    .v-tab {
      &:hover {
        text-decoration: none;
      }
    }
    v-tab .v-tab--active {
      &:hover {
        text-decoration: none;
      }
    }
    .menu-item {
      &.router-link-exact-active {
        text-decoration: none;
        font-weight: 700;
        font-size: 14px;
        color: #5c2dd3;
        letter-spacing: 0.4px;
        font-family: 'Montserrat', sans-serif;
      }
    }
    .v-tab {
      text-decoration: none;
      font-weight: 600;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.6);
      letter-spacing: 0.4px;
      font-family: 'Montserrat', sans-serif;
      &.v-tab--active {
        text-decoration: none;
        font-weight: 700;
        font-size: 12px;
        color: #5c2dd3;
        letter-spacing: 0.4px;
        font-family: 'Montserrat', sans-serif;
      }
      &.v-tab--disabled {
        text-decoration: none;
        font-weight: 600;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.6);
        letter-spacing: 0.4px;
        font-family: 'Montserrat', sans-serif;
      }
    }
  }
  .lineMenu {
    margin-top: 8px;
    margin-bottom: 1rem;
  }
}
.margin-switch {
  margin-left: 40px !important;
}
</style>
<style lang="scss">
.page-content {
  .header {
    .v-btn:not(.v-btn--round).v-size--small {
      height: 30px;
      min-width: 30px;
    }
  }
}
.autorisation {
  .theme--light.v-label {
    font-size: 12px;
  }
}
.block-menu-users {
  .v-tabs-bar {
    border-radius: inherit;
    height: 42px !important;
  }
  .v-slide-group__content {
    border-bottom: 1px solid #e5e5e5;
  }
}
</style>
